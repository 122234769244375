import React from "react";
import "../styles/NavBar.scss";
import logo from "../images/logo_bold.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NavBar() {
  return (
    <div className="navBar">
      <a href="/">
        <div className="Navlogo">
          <img src={logo} alt="Kernel Biomedical" />
          <span>Papers</span>
        </div>
      </a>
      <div className="Navbuttons">
        <a href="https://kernelbiomedical.com/" className="gotokernel">
          Go to KerNel Biomedical website
        </a>
        <a href="/" className="homebutton">
          <FontAwesomeIcon icon={"home"} />
        </a>
      </div>
    </div>
  );
}

export default NavBar;
