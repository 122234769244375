import React from "react";
import "../styles/Footer.scss";

import linkedin from "../images/linkedin.png";
import twitter from "../images/twitter.png";

function Footer() {
  return (
    <div className="footer">
      <div className="links">
        <a href="https://kernelbiomedical.com/">KerNel Biomedical</a>
        <a href="mailto:contact@kernelbiomedical.com">Contact us</a>
      </div>
      <div className="rightfooter">
        <a href="https://www.linkedin.com/company/kernel-biomedical/">
          <img src={linkedin} alt="linkedin" />
        </a>
        <a href="https://twitter.com/KerNelBiomed">
          <img src={twitter} alt="twitter" />
        </a>
        <p>© KerNel Biomedical</p>
      </div>
    </div>
  );
}

export default Footer;
